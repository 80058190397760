<style scoped lang="less">
.exam_online_detail {
  .form-inline-8 {
    width: 33.3%;
    display: inline-block;
  }
  .form-inline-12 {
    width: 49.9%;
    display: inline-block;
  }
  @media screen and (max-width: 1366px) {
    .form-inline-8 {
      width: 49.9%;
      display: inline-block;
    }
  }
}
</style>
<style lang="less">
.exam_online_detail {
  .no-label {
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
</style>
<template>
  <div class="exam_online_detail">
    <el-dialog :visible.sync="dialog" width="60%" :title="title">
      <el-form
        :model="items"
        :rules="rules"
        label-width="105px"
        ref="detailForm"
      >
        <el-form-item label="考试名称" prop="examName">
          <el-input
            v-model="items.examName"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开始时间"
          prop="answerStartTime"
          class="form-inline-8"
        >
          <el-date-picker
            v-model="items.answerStartTime"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="结束时间"
          prop="answerEndTime"
          class="form-inline-8"
        >
          <el-date-picker
            v-model="items.answerEndTime"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="考试时间"
          prop="examDuration"
          class="form-inline-8"
        >
          <el-input-number v-model="items.examDuration" :max="9999">
          </el-input-number>
          分钟
        </el-form-item>
        <el-form-item
          label="最短答题时间"
          prop="shortDuration"
          class="form-inline-8"
        >
          <el-input-number v-model="items.shortDuration" :max="9999">
          </el-input-number>
          分钟
        </el-form-item>
        <el-form-item label="答题要求" prop="answerDesc">
          <el-input
            v-model="items.answerDesc"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="500"
            show-word-limit
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="出卷次数" prop="rollNum" class="form-inline-8">
          <el-input-number v-model="items.rollNum" :max="9999">
          </el-input-number>
          ≤50
        </el-form-item>
        <el-form-item label="答题次数" prop="answerNum" class="form-inline-8">
          <el-input-number v-model="items.answerNum" :max="9999">
          </el-input-number>
        </el-form-item>
        <el-form-item label="" prop="paperData" class="no-label">
          <el-table :data="items.paperData" max-height="200">
            <el-table-column
              v-for="(col, index) in cols"
              :key="index"
              :prop="col.prop"
              :label="col.label"
              :min-widht="col.width"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button type="primary" @click="selectPaper(scope.row)">
                  选择试卷
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="是否可查看历史试卷"
          prop="historyFlag"
          class="form-inline-12"
        >
          <el-radio-group v-model="items.historyFlag">
            <el-radio v-for="(v, i) in yon" :label="v.value" :key="i">
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="显示记录得分"
          prop="showFlag"
          class="form-inline-12"
        >
          <el-radio-group v-model="items.showFlag">
            <el-radio v-for="(v, i) in yon" :label="v.value" :key="i">
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="禁止复制粘贴"
          prop="copyFlag"
          class="form-inline-12"
        >
          <el-radio-group v-model="items.copyFlag">
            <el-radio v-for="(v, i) in yon" :label="v.value" :key="i">
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="取消文本框文件按钮"
          prop="buttonFlag"
          class="form-inline-12"
        >
          <el-radio-group v-model="items.buttonFlag">
            <el-radio v-for="(v, i) in yon" :label="v.value" :key="i">
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button @click="hanleSave" type="primary">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="paper.dialog" title="添加试卷" width="45%">
      <el-table :data="items.paperData" height="400" highlight-current-row>
        <el-table-column
            type="index"
          align="center"
          label="序号"
          width="70"
        ></el-table-column>
        <el-table-column
          v-for="(col, index) in cols"
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :min-width="col.width"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleSelect(scope.row)">
              选择
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paperDialogFalse()">取消</el-button>
        <el-button @click="cancelPaperList" type="primary">
          取消添加试卷
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "exam_online_detail",
  props: {
    editData: Object, // 编辑数据
  },
  data() {
    return {
      title: "添加",
      hanleSaveType: 0,
      dialog: false,
      loading: false,
      yon: [
        { label: "否", value: "0" },
        { label: "是", value: "1" },
      ],
      cols: [
        { label: "试卷名称", prop: "paperName" },
        { label: "试卷总分", prop: "totalScore" },
        { label: "创建人", prop: "name" },
        { label: "创建时间", prop: "createTime", width: 140 },
      ],
      rules: {
        examName: [
          { required: true, message: "请输入考试名称", trigger: "blur" },
        ],
        answerStartTime: [
          { required: true, message: "请输入开始时间", trigger: "blur" },
        ],
        answerEndTime: [
          { required: true, message: "请输入结束时间", trigger: "blur" },
        ],
        examDuration: [
          { required: true, message: "请输入考试时长", trigger: "blur" },
        ],
        answerDesc: [
          { required: true, message: "请输入答题要求", trigger: "blur" },
        ],
        rollNum: [
          { required: true, message: "请输入出卷次数", trigger: "blur" },
        ],
        paperData: [{ required: true, message: "请选择试卷" }],
      },

      // 表单
      items: {
        examName: null, // 考试名称
        answerStartTime: null, // 开始时间
        answerEndTime: null, // 作答结束时间
        examDuration: 0, // 考试时长
        shortDuration: 0, // 最短答题时长
        answerDesc: null, // 答题要求
        rollNum: 0, // 出卷次数
        answerNum: 0, // 答题次数
        paperData: [], // 选择的试卷列表
        historyFlag: "0", // 显示可查看历史试卷 0否 1是
        showFlag: "0", // 显示记录得分 0否 1是
        copyFlag: "0", // 禁止复制粘贴 0否 1是
        buttonFlag: "0", // 取消文本框文件按钮 0否 1是
      },
      // 试卷id
      paperId: null,

      //章节id
      id: null,

      paper: {
        dialog: false,
        data: [],
      },
    };
  },
  computed: {
    // 课程信息
    course() {
      return this.$store.getters.getTCourse;
    },
    // 用户 教师信息
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    // 查询试卷列表
    queryEduTestPaperList(){
      this.openLoadingView()
      let formData=new FormData();
      formData.append("producerId",this.user.id); //教师id
      formData.append("courseId",this.course.id); //课程id
      this.$axios_supermallData.post("/hnjxjy-core/eduTestPaper/list",formData).then(resp => {
        if (resp.data.code == 2000) {
          this.items.paperData = resp.data.data;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    // 根据教师id 课程id  试卷id 查询试卷
    eduTestPaperList(type){
      this.openLoadingView();
      let formdata=new FormData();
      formdata.append("producerId",this.user.id); //教师id
      formdata.append("courseId",this.course.id); //课程id
      if (this.paperId != null && type == 1){
        formdata.append("id",this.paperId); //试卷id
      }
      this.$axios_supermallData.post("/hnjxjy-core/eduTestPaper/list",formdata).then(res => {
        if (res.data.code == 2000) {
          this.$set(this.items,'paperData',res.data.data)
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    // 查询在线考试信息
    queryEduCourseExam(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseExamSet/queryEduCourseExamSetById/" + this.id)
          .then(resp => {
            if (resp.data.code == 2000) {
              this.items = resp.data.data;
              this.$set(this.items,'historyFlag',resp.data.data.historyFlag.toString())
              this.$set(this.items,'showFlag',resp.data.data.showFlag.toString())
              this.$set(this.items,'copyFlag',resp.data.data.copyFlag.toString())
              this.$set(this.items,'buttonFlag',resp.data.data.buttonFlag.toString())
              this.paperId = resp.data.data.paperId;
              this.eduTestPaperList(1);
            }else {
              this.loadingView.close();
            }
          }).catch((e)=>{
            this.$message.error(e)
            this.loadingView.close();
          });
    },
    hanleSave() {
      this.$refs["detailForm"].validate((valid) => {
        if (!valid) return false;
        if (this.paperId == null){
          return this.$message.error("请选择试卷");
        }
        if (this.hanleSaveType == 0){
          this.add();
        }else {
          this.editor();
        }
      })
    },
    // 添加
    add(){
      this.openLoadingView();
      let formData=new FormData();
      formData.append("examName",this.items.examName); //考试名称
      formData.append("paperId",this.paperId); //试卷id
      formData.append("answerStartTime",this.items.answerStartTime); //作答开始时间
      formData.append("answerEndTime",this.items.answerEndTime); //作答结束时间
      formData.append("examDuration",this.items.examDuration); //考试时长
      formData.append("shortDuration",this.items.shortDuration); //最短答题时长
      formData.append("answerDesc",this.items.answerDesc); //答题要求
      formData.append("rollNum",this.items.rollNum); //出卷次数
      formData.append("answerNum",this.items.answerNum); //答题次数
      formData.append("historyFlag",this.items.historyFlag); //显示可查看历史试卷 0否 1是
      formData.append("showFlag",this.items.showFlag); //显示记录得分 0否 1是
      formData.append("copyFlag",this.items.copyFlag); //禁止复制粘贴 0否 1是
      formData.append("buttonFlag",this.items.buttonFlag); //取消文本框文件按钮 0否 1是
      formData.append("producerId",this.user.id); //教师id
      formData.append("courseId",this.course.id); //课程id
      this.$axios_supermallData.post("/hnjxjy-core/eduCourseExamSet/add",formData).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功")
          this.$emit("http200");
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
      this.items = {
        examName: null, // 考试名称
        answerStartTime: null, // 开始时间
        answerEndTime: null, // 作答结束时间
        examDuration: 0, // 考试时长
        shortDuration: 0, // 最短答题时长
        answerDesc: null, // 答题要求
        rollNum: 0, // 出卷次数
        answerNum: 0, // 答题次数
        paperData: [], // 选择的试卷列表
        historyFlag: "0", // 显示可查看历史试卷 0否 1是
        showFlag: "0", // 显示记录得分 0否 1是
        copyFlag: "0", // 禁止复制粘贴 0否 1是
        buttonFlag: "0", // 取消文本框文件按钮 0否 1是
      };
      this.dialog = false;
    },
    // 编辑
    editor(){
      this.openLoadingView();
      let params = {
        id: this.id,
        examName: this.items.examName,
        paperId: this.paperId,
        answerStartTime: this.items.answerStartTime,
        answerEndTime: this.items.answerEndTime,
        examDuration: this.items.examDuration,
        shortDuration: this.items.shortDuration,
        answerDesc: this.items.answerDesc,
        rollNum: this.items.rollNum,
        answerNum: this.items.answerNum,
        historyFlag: this.items.historyFlag,
        showFlag: this.items.showFlag,
        copyFlag: this.items.copyFlag,
        buttonFlag: this.items.buttonFlag,
        producerId: this.user.id,
        courseId: this.course.id
      }
      this.$axios_supermall.post("hnjxjy-core/eduCourseExamSet/edit",params).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功")
          this.$emit("http200");
          this.loadingView.close();
        }else {
          this.$message.error("操作失败")
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
      this.items = {
        examName: null, // 考试名称
        answerStartTime: null, // 开始时间
        answerEndTime: null, // 作答结束时间
        examDuration: 0, // 考试时长
        shortDuration: 0, // 最短答题时长
        answerDesc: null, // 答题要求
        rollNum: 0, // 出卷次数
        answerNum: 0, // 答题次数
        paperData: [], // 选择的试卷列表
        historyFlag: "0", // 显示可查看历史试卷 0否 1是
        showFlag: "0", // 显示记录得分 0否 1是
        copyFlag: "0", // 禁止复制粘贴 0否 1是
        buttonFlag: "0", // 取消文本框文件按钮 0否 1是
      };
      this.dialog = false;
    },
    selectPaper(row) {
      if (row) {
        this.items.paperData = [];
        this.paper = {
          dialog: true,
          data: [],
        };
        setTimeout(()=>{
          this.openLoadingView();
        },100)
        setTimeout(()=>{
          this.eduTestPaperList(0);
        },500)
      }
    },
    initPaperData(){
      this.items.paperData = [];
      let data = {
        createTime: null,
        id:  null,
        name:  null,
        paperName:  null,
        totalScore:  null,
      }
      this.items.paperData.push(data);
    },
    // 取消添加试卷
    cancelPaperList() {
      this.paperId = null;
      this.paper.dialog = false;
      this.initPaperData();
    },
    handleSelect(row) {
      this.paperId = row.id;
      this.paper.dialog = false;
      this.eduTestPaperList(1);
    },
    cancel(){
      this.dialog = false;
      this.$refs.detailForm.resetFields()
    },
    paperDialogFalse(){
      this.paper.dialog = false;
      this.eduTestPaperList(1);
    }
  },
};
</script>
