
<style scoped lang="less">
.t_exam_online {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
}
</style>
<template>
  <div class="t_exam_online">
    <div class="stu-module-header">
      <div class="stu-module-title">在线考试</div>
      <div>
        <el-button type="primary" @click="add">添加</el-button>
        <el-button type="primary" @click="del()">删除</el-button>
      </div>
    </div>
    <div class="content">
      <el-form :model="form" size="medium" inline ref="exam_online_ref">
        <el-form-item label="考试名称" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="时间" prop="time">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="queryListByTeacher()" type="primary">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :height="height" ref="multipleTable" @selection-change="handleSelectionChange" stripe>
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column label="序号" width="65" prop="id" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :label="col.label"
          :prop="col.prop"
          :min-width="col.width"
          :sortable="col.sortable"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row, scope.$index)">
              编辑
            </el-button>
            <el-button type="text" @click="read(scope.row, scope.$index)">
              批阅
            </el-button>
            <el-button type="text" @click="preview(scope.row)">
              预览
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <add-or-edit @http200="http200" ref="examOnline_dialog" />
  </div>
</template>

<script>
import AddOrEdit from "./module/AddOrEdit.vue";
export default {
  name: "t_exam_online",
  components: { AddOrEdit },
  data() {
    return {
      multipleTable: [],
      height: window.innerHeight - 380,
      active: "one",
      form: {
        name: null,
        time: null,
      },
      siteNameOptions: [],
      gradeOptions: [],

      tableData: [],
      column: [
        { label: "考试名称", prop: "examName" },
        { label: "开始时间", prop: "answerStartTime", width: 140 },
        { label: "结束时间", prop: "answerEndTime", width: 140 },
        { label: "考试时长", prop: "examDuration" },
        { label: "试卷总分", prop: "totalScore" },
      ],
      total: 0,
      pageSize: 20,
      current: 1,
    };
  },
  computed: {
    // 课程信息
    course() {
      return this.$store.getters.getTCourse;
    },
    // 用户 教师信息
    user() {
      return this.$store.getters.getUser;
    },
  },
  activated() {
    this.queryListByTeacher()
  },
  methods: {
    query() {},
    queryListByTeacher(){
      this.openLoadingView()
      let formdata=new FormData();
      formdata.append("producerId",this.user.id); //教师id
      formdata.append("courseId",this.course.id); //课程id
      if (this.form.name != null){
        formdata.append("examName",this.form.name);
      }
      if (this.form.time != null && this.form.time.length > 1){
        formdata.append("startTime",this.form.time[0]);
        formdata.append("endTime",this.form.time[1]);
      }
      this.$axios_supermallData.post("/hnjxjy-core/eduCourseExamSet/queryListByTeacher",formdata).then(resp => {
        if (resp.data.code == 2000) {
          if (resp.data.data !== null){
            let array = [];
            array.push(resp.data.data);
            this.tableData = array;
          }
          this.loadingView.close()
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    init() {},
    add() {
      this.$refs["examOnline_dialog"].dialog = true;
      this.$refs["examOnline_dialog"].hanleSaveType = 0;
      this.$refs["examOnline_dialog"].paperId = null;
      this.$refs["examOnline_dialog"].title = "添加";
      this.$refs["examOnline_dialog"].initPaperData();
    },
    del() {
      let array = [];
      for (let i = 0; i < this.multipleTable.length; i++) {
        array.push(JSON.stringify(this.multipleTable[i].id));
      }
      if (array.length == 0) {
        return  this.$message.error("请选择一条数据");
      }
      this.openLoadingView();
      this.$axios_supermallData({
        url: "hnjxjy-core/eduCourseExamSet/remove",
        method: "GET",
        params: {
          id: array.toString()
        },
      }).then((res) => {
        if (res.data.code == 2000) {
          this.$message.success("操作成功")
          this.queryListByTeacher();
        }else {
          this.$message.error("操作失败")
          this.loadingView.close()
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.course();
      })
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    // 编辑
    edit(row, index) {
      this.$refs["examOnline_dialog"].dialog = true;
      setTimeout(()=>{
        this.openLoadingView();
        this.$refs["examOnline_dialog"].title = "编辑";
        this.$refs["examOnline_dialog"].hanleSaveType = 1;
        this.$refs["examOnline_dialog"].id = row.id;
      },100)
      setTimeout(()=>{
        this.$refs["examOnline_dialog"].queryEduCourseExam();
      },500)
    },
    // 批阅
    read(row, index) {
      if (row) {
        this.$router.push({
          path: "online/checked",
          query: {
            workSetId: row.id,
            courseId: row.courseId,
          },
        });
      }
    },
    // 预览
    preview(row) {
      if (row) {
        this.$router.push({
          path:"online/preview",
          query:{
            id:row.id
          }
        });
      }
    },
    reset() {
      this.$refs["exam_online_ref"].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleTable = val;
    },
    http200(){
      this.queryListByTeacher();
    },
  },
};
</script>
